.nav-option {
  width: auto;
  padding: 10px;
  position: relative;
}

.nav-option:hover::after {
  content: "";
  position: absolute;
  background-color: #000;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  border-radius: 2px;
  animation-name: underline;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
.dropdown-nav {
  font-weight: 400;
}
.logo-container {
  max-width: 150px;
}
.logo-container > img {
  object-fit: contain;
  max-width: 100%;
  min-width: 120px;
}
.dropdown-nav::after {
  /* content: url("../../assets/icons/dropdown_icon.svg"); */
  margin-left: 6px;
  display: inline-block;
  margin-bottom: 2px;
}
.humburger-btn.p-button {
  padding: 0.5rem;
}
.humburger-btn.p-button:focus {
  box-shadow: none;
}
.humburger-btn.p-button:active {
  box-shadow: none;
}
.humburger-btn.p-button.p-button-text:not(:disabled):active {
  background-color: #fff;
  color: #000;
  border-color: transparent;
}
.header-link {
  color: #000;
  text-decoration: none;
}
.nav-highlight {
  font-weight: 600;
}

@keyframes underline {
  0% {
    width: 0%;
    left: 0;
  }
  100% {
    width: 100%;
    left: 0;
  }
}
